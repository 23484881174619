<template>
  <div class="xiangmu">
    <div class="xiangmutop">
      <div class="xiangmutopt">
        <img
          src="https://task.hozoin.cn/Works/20210414/sj-7911164238.png"
          alt=""
        />
      </div>
      <div class="xiangmutopb" v-if="fabutopzhanshi">
        <div class="xiangmutopb_btn">
          <div class="xiangmutopb_btnsmall" @click="fabubtn">免费发布任务</div>
        </div>
        <div class="xiangmutopb_text">获取优质服务方案</div>
      </div>
    </div>
    <div class="chengguotype">
      <div class="chengguotype_l">
        <ul class="chengguotype_big">
          <li>专业分类</li>
          <li @click="type_allclick">全部</li>
          <li @click="type_hjclick">环境设计</li>
          <li @click="type_cpclick">产品设计</li>
          <li @click="type_sjclick">视觉传达设计</li>
        </ul>
        <ul class="chengguotype_small">
          <li>专业细分</li>
          <li @click="typesmall_change()">全部</li>
          <li
            v-for="(item, index) in type_tongyong"
            :key="index"
            @click="typesmall_change(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
        <ul class="chengguotype_jz">
          <li>项目进展</li>
          <li @click="type_jzclick()">全部</li>
          <li @click="type_jzclick(2)">报名中</li>
          <li @click="type_jzclick(1)">进行中</li>
          <li @click="type_jzclick(5)">已完成</li>
        </ul>
      </div>
      <div class="chengguotype_r" v-if="false">
        <el-select v-model="optionsvalue" placeholder="默认">
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <ul class="xiangmutype_ul">
      <li
        class="xiangmutype_ulli"
        v-for="item in xiangmutype_ul"
        :key="item.id"
        :class="item.title ? '' : 'Zhanping_bisheullizero'"
        @click="xiangmutype_ulli(item.id)"
      >
        <div class="xiangmutype_ulliimg">
          <img v-lazy="item.titlepic" alt="" />
          <div
            class="xiangmutype_ulliimg_time"
            v-if="item.stop_time && item.taskpace < 2"
          >
            <span>距离报名截止</span>
            <input type="text" readonly="readonly" v-model="item.stop_time" />
          </div>
        </div>
        <div class="xiangmutitlebigbox">
          <div class="xiangmutitlebox">
            <div>{{ item.title }}</div>
            <div>￥{{ item.money }}</div>
          </div>
          <div class="xiangmutitlebigbox_bmbox">
            <div class="xiangmutitlebigbox_bm">{{ item.taskpace }}</div>
          </div>
        </div>
        <div class="xiangmutype_ullird">
          <icon-svg icon-class="iconlook" />
          <span>{{ item.browse }}</span>
          <icon-svg icon-class="iconmessages" />
          <span>{{ item.plnum == null ? "0" : item.plnum }}</span>
          <icon-svg icon-class="icontotal" />
          <span>{{ item.sign }}</span>
        </div>
      </li>
      <li class="lizero" v-if="lizero">
        <img
          src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
          alt=""
        />
      </li>
    </ul>
    <el-pagination
      :pager-count="5"
      @current-change="currentchange"
      :page-size="pagesize"
      background
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".xiangmu .chengguotype_big li", function () {
  $(this).css("color", "#4FC6FF");
  $(this).siblings().css("color", "#333");
  $(".xiangmu .chengguotype_small li").eq(1).css("color", "#4FC6FF");
  $(".xiangmu .chengguotype_small li").eq(1).siblings().css("color", "#333");
  $(".xiangmu .chengguotype_jz li").eq(1).css("color", "#4FC6FF");
  $(".xiangmu .chengguotype_jz li").eq(1).siblings().css("color", "#333");
});
$(document).on("click", ".xiangmu .chengguotype_small li", function () {
  $(this).css("color", "#4FC6FF");
  $(this).siblings().css("color", "#333");
  $(".xiangmu .chengguotype_jz li").eq(1).css("color", "#4FC6FF");
  $(".xiangmu .chengguotype_jz li").eq(1).siblings().css("color", "#333");
});
$(document).on("click", ".xiangmu .chengguotype_jz li", function () {
  $(this).css("color", "#4FC6FF");
  $(this).siblings().css("color", "#333");
});
export default {
  components: {},
  data() {
    return {
      fabutopzhanshi: false,
      lizero: false,
      timetext: "",
      total: 0,
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      activenum: 5,
      xiangmutopt_timg1: true,
      xiangmutopt_timg1_f: false,
      xiangmutopt_timg2: true,
      xiangmutopt_timg2_f: false,
      xiangmutopt_timg3: true,
      xiangmutopt_timg3_f: false,
      xiangmutopt_timg4: true,
      xiangmutopt_timg4_f: false,
      xiangmutopt_timg5: true,
      xiangmutopt_timg5_f: false,
      imgall: {
        img1: "https://www.shijianpro.com/Public/images/task/item_process_1_1.png",
        img1_f:
          "https://www.shijianpro.com/Public/images/task/item_process_1_0.png",
        img2: "https://www.shijianpro.com/Public/images/task/item_process_2_1.png",
        img2_f:
          "https://www.shijianpro.com/Public/images/task/item_process_2_0.png",
        img3: "https://www.shijianpro.com/Public/images/task/item_process_3_1.png",
        img3_f:
          "https://www.shijianpro.com/Public/images/task/item_process_3_0.png",
        img4: "https://www.shijianpro.com/Public/images/task/item_process_4_1.png",
        img4_f:
          "https://www.shijianpro.com/Public/images/task/item_process_4_0.png",
        img5: "https://www.shijianpro.com/Public/images/task/item_process_5_1.png",
        img5_f:
          "https://www.shijianpro.com/Public/images/task/item_process_5_0.png",
      },
      options: [
        {
          value: "选项1",
        },
        {
          value: "选项2",
        },
        {
          value: "选项3",
        },
        {
          value: "选项4",
        },
        {
          value: "选项5",
        },
      ],
      optionsvalue: "",
      type_tongyong: [],
      typebig: "",
      typesmall: "",
      pagesize: 12,
      type_jzclickid: "",
      timer: null, //定时器
      bbbbbb: [],
      xiangmutype_ul: [],
      userinfo: {},
      smalltypeid: "",
    };
  },
  methods: {
    type_allclick() {
      var that = this;
      that.typebig = "";
      that.typesmall = "";
      that.typeaxios();
    },
    type_hjclick() {
      var that = this;
      that.typebig = "22";
      that.typesmall = "";
      that.typeaxios();
      that.typeaxios_small(22);
    },
    type_cpclick() {
      var that = this;
      that.typebig = "23";
      that.typesmall = "";
      that.typeaxios();
      that.typeaxios_small(23);
    },
    type_sjclick() {
      var that = this;
      that.typebig = "24";
      that.typesmall = "";
      that.typeaxios();
      that.typeaxios_small(24);
    },
    typeaxios_small(id) {
      //小类分类请求
      var that = this;
      this.$axios({
        url: "/api/getClassList",
        method: "post",
        data: {
          class: id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.type_tongyong = res.data.result;
        } else {
          // console.log("请求失败");
          that.chengguo_btmul = "";
        }
      });
    },
    typeaxios() {
      //大类
      this.getTimerData(1, this.typebig);
    },
    typesmall_change(id) {
      // 小类
      this.smalltypeid = id;
      this.getTimerData(1, this.typebig, id);
    },
    type_jzclick(id) {
      //进展
      this.xiangmutype_ul = [];
      this.type_jzclickid = id;
      this.getTimerData(1, this.typebig, this.smalltypeid);
    },
    currentchange(index) {
      document.documentElement.scrollTop = 0;
      this.getTimerData(index);
    },
    time(timenum, index) {
      // console.log(timenum, index);
      var that = this;
      var timenow = parseInt(new Date().valueOf()); //这是当前的时间戳
      var timeend = timenum * 1000;
      var timecha = timeend - timenow; //项目结束的时间戳  -   当前的时间戳  =距结束时间
      if (timecha < 0) {
        that.xiangmutype_ul[index].stop_time = 1;
      } else {
        var days = parseInt(timecha / parseInt(1000 * 60 * 60 * 24));
        timecha = timecha % parseInt(1000 * 60 * 60 * 24);
        var hours = parseInt(timecha / parseInt(1000 * 60 * 60));
        timecha = timecha % parseInt(1000 * 60 * 60);
        var minutes = parseInt(timecha / parseInt(1000 * 60));
        timecha = timecha % parseInt(1000 * 60);
        var seconds = parseInt(timecha / parseInt(1000));
        var timetext =
          days + "天 " + hours + "时 " + minutes + "分 " + seconds + "秒 ";
        that.xiangmutype_ul[index].stop_time = timetext;
      }
    },
    //获取时间数据
    getTimerData(page, typebig, id) {
      var that = this;
      this.xiangmutype_ul = [];
      that
        .$axios({
          url: "/api/getProject",
          method: "post",
          data: {
            page,
            limit: that.pagesize,
            classid: typebig,
            task_type: id,
            taskpace: that.type_jzclickid,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.lizero = false;
            let list = res.data.result.list;
            that.bbbbbb = list; //循环它的时间戳
            list.map((re) => {
              if (re.taskpace == 0) {
                re.taskpace = "报名中";
              } else if (re.taskpace == 5 || re.taskpace == 6) {
                re.taskpace = "已完成";
              } else {
                re.taskpace = "进行中";
              }
            });
            that.xiangmutype_ul = JSON.parse(JSON.stringify(list)); //通过浅拷贝改变引用地址
            that.total = res.data.result.count;
            var count = list.length % 4;
            var li = "";
            if (count == 2) {
              that.xiangmutype_ul.push(li, li);
            } else if (count == 3) {
              that.xiangmutype_ul.push(li);
            }
            that.activeSetInt();
          } else {
            that.lizero = true;
          }
        });
    },
    //定时执行
    activeSetInt() {
      let that = this;
      that.timer = setInterval(() => {
        for (var i in that.bbbbbb) {
          // 1、循环的是b的时间戳
          that.time(that.bbbbbb[i].stop_time, i); //项目结束的时间戳 不变的
        }
      }, 1000);
    },
    xiangmutype_ulli(id) {
      window.clearTimeout(this.timer);
      this.$router.push({ path: "/Xiangmu_C", query: { data: id } });
    },
    fabubtn() {
      if (this.userinfo != null) {
        if (this.userinfo.groupid == 2) {
          this.$router.push({ path: "/Sjfabu" });
        } else {
          this.$message({
            message: "只有企业可发布项目！",
            duration: 1000,
          });
        }
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
  },
  created() {
    this.fenxiang(
      "和作营-项目大厅",
      "面向学生的真实社会项目，从设计到完成全过程实践，让设计不再停留在理论中。",
      "https://mmbiz.qpic.cn/mmbiz_png/vBjAjicW4DmC6McbmE18LUoeX3ibDAIiawYO72fKWibdSgaqfus1j5WxJR0SN7nOgjNFjGsmk3jPqecG1rqOsUxTzg/0?wx_fmt=png"
    );
    //获取时间数据
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    if (this.userinfo != null) {
      if (that.userinfo.groupid == 2) {
        that.fabutopzhanshi = true;
      }
    }
    this.getTimerData(1);
  },
  destoryed() {
    window.clearTimeout(this.timer);
  },
};
</script>
<style>
@import "../assets/css/xiangmu.css";
</style>
